<div
  class="container-fluid d-flex justify-content-center tw-flex-grow tw-items-center"
>
  <div class="mt-3 tw-max-w-sm">
    <div class="card">
      <div class="card-body">
        @switch (errorType) {
          @case (types.NOT_FOUND) {
            <ng-container [ngTemplateOutlet]="notFound"></ng-container>
          }
          @case (types.UNAUTHORIZED) {
            <ng-container [ngTemplateOutlet]="unauthorised"></ng-container>
          }
          @case (types.PERMISSION_DENIED) {
            <ng-container [ngTemplateOutlet]="permissionDenied"></ng-container>
          }
          @default {
            <ng-container [ngTemplateOutlet]="error"></ng-container>
          }
        }
      </div>
      <div class="card-body">
        <a [href]="mailTo" class="btn btn-primary me-2">Contact support</a>
        <button class="btn btn-outline-primary" [routerLink]="['/']">
          Homepage
        </button>
      </div>
      <div class="card-footer text-muted font-monospace">
        @if (errorMessage) {
          <div class="small">{{ errorMessage }}</div>
        }
        <div class="small">{{ timestamp }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #error>
  <h1 class="h2 card-title">Error</h1>
  <p>You've hit an error which wasn't handled.</p>
  <p>
    Email&nbsp;<a href="{{ mailTo }}" class="text-break"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="bg-light font-monospace border p-1">{{ id }}</span>
    who will be able to help with the issue.
  </p>
</ng-template>

<ng-template #notFound>
  <h1 class="h2 card-title">Not Found</h1>
  <p>There is nothing on this page.</p>
  <p>
    Email&nbsp;<a href="{{ mailTo }}" class="text-break"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="bg-light font-monospace border p-1">{{ id }}</span>
    who might be able to find what you were looking for.
  </p>
</ng-template>

<ng-template #permissionDenied>
  <h1 class="h2 card-title">Permission Denied</h1>
  <p>You don't have access to this.</p>
  <p>
    Email&nbsp;<a href="{{ mailTo }}" class="text-break"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="bg-light font-monospace border p-1">{{ id }}</span>
    who might be able to give you permission to see it.
  </p>
</ng-template>

<ng-template #unauthorised>
  <h1 class="h2 card-title">Unauthorised</h1>
  <p>We were unable to log you in.</p>
  <p>
    Email&nbsp;<a href="{{ mailTo }}" class="text-break"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="bg-light font-monospace border p-1">{{ id }}</span>
    who will be able to help get you access.
  </p>
</ng-template>
